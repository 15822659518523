import Link from 'next/link';

import { OptimizelyFeatureFlags } from '@tgg/common-types';

import { useFlagsContext } from '../../contexts';

import { STORY_BOX_SIZES, height, width } from './StoryBox.constants';
import {
    StyledButton,
    StyledButtonContainer,
    StyledContent,
    StyledHeading,
    StyledImageStandalone,
    StyledPill,
    StyledRichTextContent,
    StyledTagItem,
    StyledTagLink,
    StyledTagsList,
    StyledWrapper,
} from './StoryBox.styled';
import { StoryBoxProperties } from './StoryBox.types';

export const StoryBox = ({
    button,
    secondaryButton,
    image,
    paragraph,
    pill,
    size,
    tags,
    title,
    variant,
}: StoryBoxProperties) => {
    const {
        flags: {
            [OptimizelyFeatureFlags.REJOIN_JOURNEY]: {
                enabled: isRejoinJourneyEnabled,
            },
        },
    } = useFlagsContext();

    const buttonStyle =
        variant === 'light blue' ? 'secondary-blue-white' : 'secondary';

    return (
        <StyledWrapper $variant={variant} data-testid="story-box-wrapper">
            {image?.image && (
                <StyledImageStandalone
                    {...{
                        ...image,
                        height,
                        width,
                        overrideSizes: STORY_BOX_SIZES,
                    }}
                    $size={size}
                />
            )}
            <StyledContent>
                {tags && (
                    <StyledTagsList>
                        {tags?.map(tag => (
                            <StyledTagItem key={tag.href}>
                                <StyledTagLink href={tag.href}>
                                    {tag.text}
                                </StyledTagLink>
                            </StyledTagItem>
                        ))}
                    </StyledTagsList>
                )}
                <StyledHeading variant="h3">{title}</StyledHeading>
                <StyledRichTextContent text={paragraph} fullWidth />
                <StyledButtonContainer>
                    <Link href={button.href} legacyBehavior>
                        <StyledButton
                            text={button.text}
                            href={button.href}
                            buttonStyle={buttonStyle}
                        />
                    </Link>
                    {isRejoinJourneyEnabled &&
                        secondaryButton &&
                        size === 'large' && (
                            <Link href={secondaryButton.href} legacyBehavior>
                                <StyledButton
                                    text={secondaryButton.text}
                                    href={secondaryButton.href}
                                    buttonStyle={buttonStyle}
                                />
                            </Link>
                        )}
                </StyledButtonContainer>
                {pill && <StyledPill>{pill}</StyledPill>}
            </StyledContent>
        </StyledWrapper>
    );
};
