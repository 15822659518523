import { useRef, useState } from 'react';

import { GymInformation, SortedGym } from '@tgg/common-types';
import { getUserPosition } from '@tgg/services';
import { calculateDistance, createTraceParameters } from '@tgg/util';

import { useAppConfigContext } from '../../contexts';

interface Prediction {
    place_id: string;
}

function getPlacePredictions(inputValue: string): Promise<Prediction[]> {
    return new Promise((resolve, reject) => {
        const autocompleteService =
            new google.maps.places.AutocompleteService();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        autocompleteService.getPlacePredictions(
            {
                input: inputValue,
                componentRestrictions: { country: 'GB' },
            },
            (predictions, status) => {
                if (
                    status === google.maps.places.PlacesServiceStatus.OK &&
                    predictions
                ) {
                    resolve(predictions);
                } else {
                    reject(status);
                }
            },
        );
    });
}

export function fetchCoordinates(placeId: string) {
    return new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        geocoder.geocode({ placeId }, (results, status) => {
            if (status === 'OK' && results && results[0]) {
                resolve(results[0].geometry.location);
            } else {
                reject(status);
            }
        });
    });
}

export function useGymsLocation(gymsData: SortedGym[]) {
    const searchInputReference = useRef<HTMLInputElement>(null);
    const [gyms, setGyms] = useState<SortedGym[]>([]);
    const [userPositionError, setUserPositionError] = useState<string | null>(
        null,
    );
    const {
        appConfig: { appLogger },
    } = useAppConfigContext();
    const loggerParameters = createTraceParameters({
        context: null,
        parameters: { operation: 'useGymsLocation' },
    });

    const findNearestGyms = (lat: number, lng: number) => {
        const returnedGyms = gymsData
            .map(gym => {
                return {
                    ...gym,
                    distance: calculateDistance(
                        lat,
                        lng,
                        gym.position.lat,
                        gym.position.lng,
                    ),
                };
            })
            .sort((a, b) => a.distance - b.distance)
            .slice(0, 3);
        setGyms(returnedGyms);
    };

    async function handleSearch(inputValue: string) {
        if (!inputValue.trim()) {
            return false;
        }
        searchInputReference.current?.blur();
        try {
            const predictions: Prediction[] =
                await getPlacePredictions(inputValue);
            if (predictions && predictions.length > 0) {
                const location = (await fetchCoordinates(
                    predictions[0].place_id,
                )) as google.maps.LatLng;
                findNearestGyms(location.lat(), location.lng());
                return true;
                // eslint-disable-next-line no-else-return
            } else {
                appLogger?.error(
                    `Prediction not found for ${inputValue}`,
                    loggerParameters,
                );
                return false;
            }
        } catch (error_) {
            appLogger?.error(
                `Error in processing your search:, ${error_}`,
                loggerParameters,
            );
            return false;
        }
    }

    const findMyLocation = () => {
        getUserPosition()
            .then(pos => {
                findNearestGyms(pos.lat, pos.lng);
            })
            .catch(error_ => {
                appLogger?.error(error_.message, loggerParameters);
                setUserPositionError(
                    'Sorry there is a problem retrieving your location, please search for a location instead',
                );
            });
    };

    const mappedGyms: GymInformation[] = gyms.map(gym => ({
        latitude: gym.position.lat,
        longitude: gym.position.lng,
        ...gym.gym,
        distance: Number(
            Math.floor((gym.distance as number) * 10) / 10 ||
                /* istanbul ignore next */ 0,
        ),
    }));

    return {
        mappedGyms,
        userPositionError,
        handleSearch,
        searchInputReference,
        findMyLocation,
    };
}
