import { postcodeValidator, useFormValidation } from '@tgg/form-validation';

import {
    ControlledBirthDateInput,
    ControlledEmailInput,
    ControlledTextInput,
} from '../ControlledInputs';

import { StyledForm, StyledSubmitButton } from './ForgottenPinForm.styled';
import { ForgottenPinFormProperties } from './ForgottenPinForm.types';

export function ForgottenPinForm({
    onSubmit,
    onValidate,
    isButtonDisabled,
}: ForgottenPinFormProperties) {
    const { control, formState, handleSubmit } = useFormValidation({
        formProps: {
            mode: 'onBlur',
            defaultValues: {
                postcode: '',
                email: '',
                dob: '',
            },
        },
        onValidate,
    });

    return (
        <StyledForm
            onSubmit={handleSubmit(onSubmit)}
            aria-label="email address form"
        >
            <ControlledEmailInput
                label="Email address"
                placeholder="enter email address"
                control={control}
            />
            <ControlledBirthDateInput
                control={control}
                showMinAgeMessage={false}
                placeholder="dd / mm / yyyy"
            />
            <ControlledTextInput
                name="postcode"
                label="Postcode"
                control={control}
                rules={{
                    validate: {
                        validator: postcodeValidator,
                    },
                }}
                isRequired
                id="postcode"
                placeholder="enter postcode"
            />
            <StyledSubmitButton
                type="submit"
                text="Retrieve my pin"
                fullWidth
                disabled={isButtonDisabled || !formState.isValid}
            />
        </StyledForm>
    );
}
