import { useRouter } from 'next/router';

import {
    GymInformation,
    SortDirection,
    sortGymsByOptions,
} from '@tgg/common-types';
import { dispatchEvent, EventKey } from '@tgg/services';
import { sortBy } from '@tgg/util';

import { GymAddressTile } from '../GymAddressTile';

import { isFindAGymPage } from './Lister.helpers';
import { StyledLister } from './Lister.styled';
import { ListerProperties, OnTileClick } from './Lister.types';

/**
 * Use the Lister component to display address and pricing information for a list of gyms.
 *
 * Lister displays in a one-column variant for mobile and a two-column variant for desktop.
 */
export function Lister({
    gyms,
    regionName,
    sortByValue = sortGymsByOptions.DISTANCE_UP,
    isFAG = false,
}: ListerProperties) {
    const router = useRouter();
    const isFindAGym = isFindAGymPage(router.asPath);
    const value = sortByValue.split('=')[0] as keyof GymInformation;
    const direction = sortByValue.split('=')[1] as SortDirection;
    const gymsSorted = sortBy(gyms, value, direction, 'distance');

    const onTileClick: OnTileClick =
        ({ gymName, price, distance }) =>
        () => {
            dispatchEvent(EventKey.GYM_TILE, {
                gym: gymName,
                membershipPrice: price,
                ...(isFindAGym && {
                    distance,
                    nearestGym: gymsSorted[0]?.gymName,
                    nearestGymDistance: gymsSorted[0]?.distance,
                    nearestGymPrice: gymsSorted[0]?.lowestPrice,
                }),
            });
        };

    return (
        <StyledLister data-testid="search-gym-list" $isFAG={isFAG}>
            {gymsSorted.reduce((accumulator, gym) => {
                if (
                    gym.gymName &&
                    gym.branchId &&
                    gym.latitude &&
                    gym.longitude
                ) {
                    accumulator.push(
                        <li key={gym.gymName}>
                            <GymAddressTile
                                {...gym}
                                regionName={regionName}
                                onTileClick={onTileClick({
                                    gymName: gym.gymName,
                                    price: gym.lowestPrice,
                                    distance: gym.distance,
                                })}
                            />
                        </li>,
                    );
                }

                return accumulator;
            }, [] as JSX.Element[])}
        </StyledLister>
    );
}
