import { EventKey, dispatchEvent, PromoCodeCopyPayload } from '@tgg/services';

import { Palette, palette } from '../../theme';
import { Icon } from '../Icon';
import { PromoCode, PromoCodeSize, PromoCodeVariant } from '../PromoCode';

import {
    promoTileColorsMap,
    StyledContainer,
    StyledDetails,
    StyledPromoCode,
    StyledPromoCodeContainer,
    StyledPromoTile,
    StyledTitle,
} from './PromoTile.styled';
import { PromoTileProperties, PromoTileVIColor } from './PromoTile.types';

export const getPaletteColour = (color: PromoTileVIColor): Palette => {
    if (color === PromoTileVIColor.OffPeak) {
        return palette.common.blue;
    }

    return promoTileColorsMap[color];
};

export const PromoTile = ({
    color,
    title,
    iconName,
    details,
    promoCode,
    gymName,
}: PromoTileProperties): React.ReactElement => {
    const onPromoCodeIconClick = (code: string) => {
        dispatchEvent<PromoCodeCopyPayload>(EventKey.PROMO_CODE_COPY, {
            promoCode: code,
            branchName: gymName ?? '',
        });
    };

    return (
        <StyledPromoTile
            color={promoTileColorsMap[color]}
            data-testid="promo-tile-container"
        >
            <StyledContainer>
                <StyledTitle>
                    <span>{title}</span>
                    <Icon
                        accessibleTitle="promoTileIcon"
                        color={getPaletteColour(color)}
                        name={iconName}
                        size={42}
                    />
                </StyledTitle>
                <hr />
                <StyledDetails>{details}</StyledDetails>
                <StyledPromoCodeContainer>
                    {promoCode ? (
                        <StyledPromoCode>
                            Use code:{' '}
                            <PromoCode
                                onClick={() => onPromoCodeIconClick(promoCode)}
                                promoCode={promoCode}
                                variant={PromoCodeVariant.GREY}
                                size={PromoCodeSize.SMALL}
                            />
                        </StyledPromoCode>
                    ) : (
                        <span>No code required. Discount already applied</span>
                    )}
                </StyledPromoCodeContainer>
            </StyledContainer>
        </StyledPromoTile>
    );
};
