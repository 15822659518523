import { useMediaQuery } from '@mui/material';

import { OptimizelyFeatureFlags } from '@tgg/common-types';

import { useFlagsContext } from '../../contexts';
import { palette, theme } from '../../theme';
import { Icon } from '../Icon';

import {
    StyledButton,
    StyledWrapper,
    StyledImageContainer,
    StyledHeader,
    StyledHeading,
    StyledImageStandalone,
    StyledParagraph,
    StyledTextContainer,
} from './RejoinBanner.styled';
import { RejoinBannerProperties } from './RejoinBanner.types';

export const RejoinBanner = ({
    id,
    title,
    image,
    description,
    button,
}: RejoinBannerProperties) => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    const {
        flags: {
            [OptimizelyFeatureFlags.REJOIN_JOURNEY]: {
                enabled: rejoinJourneyEnabled,
            },
        },
    } = useFlagsContext();

    return rejoinJourneyEnabled ? (
        <StyledWrapper id={id}>
            <StyledImageContainer>
                <StyledImageStandalone {...{ ...image }} />
            </StyledImageContainer>
            <StyledTextContainer>
                <StyledHeader>
                    <Icon
                        color={palette.common.blue}
                        name="rejoin"
                        size={isDesktop ? 60 : 40}
                    />
                    <StyledHeading variant="h2" gutterBottom={false}>
                        {title}
                    </StyledHeading>
                </StyledHeader>
                <StyledParagraph gutterBottom={false}>
                    {description}
                </StyledParagraph>
                <StyledButton text={button.text} href={button.href} />
            </StyledTextContainer>
        </StyledWrapper>
    ) : null;
};
