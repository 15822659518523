import { useEffect } from 'react';

import { nameValidator, useFormValidation } from '@tgg/form-validation';

import { useSnackBarContext } from '../../contexts';
import {
    ControlledBirthDateInput,
    ControlledEmailInput,
    ControlledGenderInput,
    ControlledTelephoneInput,
    ControlledTextInput,
    ControlledAddressInput,
    ControlledHealthToggle,
} from '../ControlledInputs';
import { StayInTouch } from '../Forms';

import {
    StyledPersonalDetailsWrapper,
    StyledForm,
    StyledSubmitButton,
    StyledSubmitButtonWrapper,
    StyledSmallText,
    StyledHorizontalRule,
} from './PersonalDetailsForm.styled';
import {
    PersonalDetailsFormProperties,
    PersonalDetailsFormSubmitData,
} from './PersonalDetailsForm.types';

/**
 * The `PersonalDetailsForm` component can be used to gather various information from the user.
 * The required inputs are denoted with an asterisk * and are validated on blur.
 * The submit button is enabled after valid inputs are entered.
 */
export const PersonalDetailsForm = ({
    isPrepopulatedWithProfileData,
    defaultValues,
    submitButtonText = 'submit',
    showHealth = true,
    showEmail = false,
    marketingTitle,
    marketingText,
    onSubmit,
    onCancel,
    onValidate,
    isEmailFieldDisabled = true,
}: PersonalDetailsFormProperties) => {
    const [, updateSnackBar] = useSnackBarContext();
    const {
        control,
        formState,
        handleSubmit,
        trigger,
        clearErrors,
        resetField,
        reset,
        getValues,
        getFieldState,
    } = useFormValidation({
        formProps: {
            mode: 'onChange',
            defaultValues: {
                searchAddress: '',
                ...defaultValues,
            } as { searchAddress: string } & PersonalDetailsFormSubmitData,
        },
        onValidate,
        updateSnackBar,
    });

    useEffect(() => {
        // Forces Validation upon loading if we have pre-populated the fields
        async function triggerValidation() {
            await trigger();
        }
        if (isPrepopulatedWithProfileData) {
            // eslint-disable-next-line no-void
            void triggerValidation();
        }
    }, [isPrepopulatedWithProfileData, trigger]);

    const { isValid, isSubmitting, errors } = formState;

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <StyledPersonalDetailsWrapper>
                <ControlledTextInput
                    name="firstName"
                    label="First Name"
                    control={control}
                    disabled={isPrepopulatedWithProfileData}
                    rules={{
                        validate: {
                            validator: nameValidator('First name'),
                        },
                    }}
                    isRequired
                    id="first-name"
                    placeholder="First Name"
                />
                <ControlledTextInput
                    name="lastName"
                    label="Last Name"
                    isRequired
                    control={control}
                    disabled={isPrepopulatedWithProfileData}
                    rules={{
                        validate: {
                            validator: nameValidator('Last name'),
                        },
                    }}
                    id="last-name"
                    placeholder="Last Name"
                />
                <ControlledGenderInput
                    control={control}
                    disabled={isPrepopulatedWithProfileData}
                />
                <ControlledBirthDateInput
                    disabled={isPrepopulatedWithProfileData}
                    control={control}
                />
                <ControlledTelephoneInput control={control} />
                <StyledSmallText gutterBottom={false}>
                    Valid UK mobile numbers are used to send your PIN to access
                    the gym
                </StyledSmallText>
                {showEmail && (
                    <ControlledEmailInput
                        label="Email"
                        disabled={isEmailFieldDisabled}
                        control={control}
                    />
                )}
            </StyledPersonalDetailsWrapper>

            <StyledHorizontalRule />

            <ControlledAddressInput
                defaultValues={defaultValues}
                resetField={resetField}
                control={control}
                errors={errors}
                trigger={trigger}
                clearErrors={clearErrors}
                reset={reset}
                getValues={getValues}
            />

            {showHealth && (
                <>
                    <StyledHorizontalRule />
                    <ControlledHealthToggle control={control} />
                </>
            )}

            <StyledHorizontalRule />

            <StayInTouch
                control={control}
                getFieldState={getFieldState}
                getValues={getValues}
                emailFieldName="commsPreferenceEmail"
                smsFieldName="commsPreferenceSms"
                heading={marketingTitle}
                description={marketingText}
                rules={{}}
            />

            <StyledSubmitButtonWrapper>
                {onCancel && (
                    <StyledSubmitButton
                        type="button"
                        buttonStyle="secondary"
                        text="cancel"
                        onClick={onCancel}
                        fullWidth
                    />
                )}

                <StyledSubmitButton
                    data-testid="personal-details-submit"
                    type="submit"
                    text={submitButtonText}
                    fullWidth
                    disabled={!isValid || isSubmitting}
                />
            </StyledSubmitButtonWrapper>
        </StyledForm>
    );
};

export default PersonalDetailsForm;
