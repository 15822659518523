import { Wrapper } from '@googlemaps/react-wrapper';
import { useMediaQuery } from '@mui/material';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

import { palette, theme } from '../../theme';
import { render } from '../GoogleMap/GoogleMap.styled';
import { Icon } from '../Icon';
import { Lister } from '../Lister';

import { useGymsLocation } from './FindAGymComponent.hooks';
import {
    StyledAlertMessage,
    StyledButtonBase,
    StyledButtonLink,
    StyledContainer,
    StyledHeading,
    StyledIcon,
    StyledResultsParagraph,
    StyledSearchInput,
    StyledSearchParagraph,
} from './FindAGymComponent.styled';
import { FindAGymComponentProperties } from './FindAGymComponent.types';

const {
    publicRuntimeConfig: { GOOGLE_MAPS_API_KEY },
} = getConfig();

export const FindAGymComponent = ({
    id,
    searchParagraph,
    gyms,
}: FindAGymComponentProperties) => {
    const router = useRouter();
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    const {
        mappedGyms,
        handleSearch,
        searchInputReference,
        userPositionError,
        findMyLocation,
    } = useGymsLocation(gyms);

    const redirect = () =>
        router.push(
            `/find-a-gym/${
                searchInputReference.current?.value
                    ? `?s=${encodeURIComponent(
                          searchInputReference.current?.value,
                      )}`
                    : ''
            }`,
        );

    const handleGymsSearch = async () => {
        if (!searchInputReference.current?.value.trim()) {
            await redirect();
        } else {
            const isValidSearch = await handleSearch(
                searchInputReference.current?.value as string,
            );
            if (!isValidSearch) {
                await redirect();
            }
        }
    };

    return (
        <StyledContainer id={id}>
            <StyledIcon
                name="gymFinder"
                size={isDesktop ? 60 : 50}
                color={palette.common.blue}
            />
            <StyledHeading variant="h2">find a gym near you</StyledHeading>
            <StyledSearchParagraph>{searchParagraph}</StyledSearchParagraph>
            <Wrapper
                apiKey={GOOGLE_MAPS_API_KEY}
                render={render}
                libraries={['geometry', 'places']}
            >
                <StyledSearchInput
                    onSubmit={handleGymsSearch}
                    placeholder="Street, town or postcode"
                    ref={searchInputReference}
                />
                {mappedGyms.length === 0 && userPositionError && (
                    <StyledAlertMessage
                        type="information"
                        text={userPositionError}
                    />
                )}
                {mappedGyms.length === 0 && (
                    <StyledButtonLink onClick={findMyLocation}>
                        <Icon name="targetLocation" />
                        <span>Use my current location</span>
                    </StyledButtonLink>
                )}
                {mappedGyms.length > 0 && (
                    <>
                        <StyledResultsParagraph gutterBottom={false}>
                            top 3 results
                        </StyledResultsParagraph>
                        <Lister gyms={mappedGyms} isFAG />
                    </>
                )}
            </Wrapper>
            {mappedGyms.length === 0 ? (
                <StyledButtonBase
                    text="find your gym"
                    onClick={handleGymsSearch}
                />
            ) : (
                <StyledButtonLink
                    onClick={() =>
                        router.push(
                            `/find-a-gym/?s=${encodeURIComponent(
                                searchInputReference.current?.value ||
                                    /* istanbul ignore next */ '',
                            )}`,
                        )
                    }
                >
                    see more gyms near you
                </StyledButtonLink>
            )}
        </StyledContainer>
    );
};
