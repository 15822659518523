import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { endOfMonth, addMonths, format } from 'date-fns';
import { Controller } from 'react-hook-form';

import { Asterisk } from '../../Asterisk';
import { Icon } from '../../Icon';
import { StyledLabel } from '../ControlledInputs.styled';

import {
    StyledDateInput,
    StyledDialogContainer,
    StyledHelperText,
} from './ControlledDateInput.styled';
import { ControlledDateInputProperties } from './ControlledDateInput.types';
import { disableSpecificDates } from './utils';

const renderDay = (day: any, _: any, dayComponentProperties: any) => {
    return (
        <PickersDay
            {...dayComponentProperties}
            data-testid={`day-${format(day, 'dd')}`}
        />
    );
};

export function ControlledDateInput({
    control,
    placeholder,
    disabledDates = [],
    disabled,
    id,
    label,
    isRequired,
    rules,
    month1AvailableSlotCount,
    month2AvailableSlotCount,
    isUserWithHDFlow,
}: ControlledDateInputProperties) {
    const maxDate = endOfMonth(addMonths(new Date(), 1));

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
                name={id}
                control={control}
                rules={rules}
                render={({ field, fieldState }) => (
                    <>
                        {label && (
                            <StyledLabel
                                $hasPaddingTop
                                data-testid={`${id}-label`}
                                htmlFor={id}
                            >
                                {label}
                                {isRequired && <Asterisk spaceBefore />}
                            </StyledLabel>
                        )}

                        <StyledDateInput
                            views={['day']}
                            DialogProps={{
                                component: StyledDialogContainer,
                            }}
                            value={field.value}
                            inputFormat="dd MMM yyyy"
                            onChange={field.onChange}
                            renderDay={renderDay}
                            renderInput={parameters => (
                                <TextField
                                    {...parameters}
                                    placeholder={placeholder}
                                    id={id}
                                    fullWidth
                                    error={fieldState.invalid}
                                    helperText={
                                        isUserWithHDFlow && (
                                            <StyledHelperText>
                                                This date is a provisional
                                                booking and dependent on
                                                completing a gym induction.
                                            </StyledHelperText>
                                        )
                                    }
                                    InputProps={{
                                        endAdornment: <Icon name="classes" />,
                                    }}
                                />
                            )}
                            componentsProps={{
                                leftArrowButton: {
                                    'data-testid': `${id}-left-arrow`,
                                },
                                rightArrowButton: {
                                    'data-testid': `${id}-right-arrow`,
                                },
                                actionBar: {
                                    // @ts-ignore
                                    'data-testid': `${id}-action-bar`,
                                },
                            }}
                            shouldDisableDate={(date: unknown) =>
                                disableSpecificDates(
                                    date as Date,
                                    disabledDates,
                                    month1AvailableSlotCount,
                                    month2AvailableSlotCount,
                                    isUserWithHDFlow,
                                )
                            }
                            disablePast
                            maxDate={maxDate}
                            disabled={disabled}
                        />
                    </>
                )}
            />
        </LocalizationProvider>
    );
}

export default ControlledDateInput;
