import { EventKey, dispatchEvent, ProductRemoveEvent } from '@tgg/services';
import { dateOrdinal, relativeUrlWithBasePath } from '@tgg/util';

import {
    StyledChangeButton,
    StyledSummary,
    StyledSelection,
    StyledSelectionTitle,
    StyledUserSelection,
    StyledSelectionValue,
} from './MembershipSummary.styled';
import { MembershipSummaryProperties } from './MembershipSummary.types';

export const formatDate = (d: string) => {
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];
    const date = new Date(d);
    const day = date.getDate();
    const month = date.getMonth();
    return `${day}${dateOrdinal(day)} ${months[month]}`;
};

const onButtonClick = (name: string, price: number, brand: string) => {
    dispatchEvent<ProductRemoveEvent>(EventKey.REMOVE_FROM_BASKET, {
        event: EventKey.REMOVE_FROM_BASKET,
        ecommerce: {
            remove: {
                products: [
                    {
                        name,
                        price,
                        brand,
                        category: 'Add Ons',
                        variant: 'Create',
                        quantity: 1,
                    },
                ],
            },
        },
    });
};

/**
 * Use the `MembershipSummary` component to show an overview of a user's shopping basket.
 *
 * This includes a link to change each of the selected items.
 */
export const MembershipSummary = ({
    gym,
    membership,
    membershipType,
    membershipPrice,
    extras,
    startDate,
}: MembershipSummaryProperties) => {
    return (
        <StyledSummary>
            <StyledUserSelection>
                <StyledSelectionTitle>gym:</StyledSelectionTitle>
                <StyledSelection>
                    <StyledSelectionValue>{gym}</StyledSelectionValue>
                    <StyledChangeButton
                        text="change"
                        buttonStyle="tertiary"
                        href={relativeUrlWithBasePath(
                            '/join-now/find-your-gym/',
                        )}
                        onClick={() =>
                            onButtonClick(gym, membershipPrice, 'Gym Name')
                        }
                    />
                </StyledSelection>
            </StyledUserSelection>
            <StyledUserSelection>
                <StyledSelectionTitle>membership:</StyledSelectionTitle>
                <StyledSelection>
                    <StyledSelectionValue>{membership}</StyledSelectionValue>
                    <StyledChangeButton
                        text="change"
                        buttonStyle="tertiary"
                        href={relativeUrlWithBasePath(
                            '/join-now/choose-membership/',
                        )}
                        onClick={() =>
                            onButtonClick(
                                membership,
                                membershipPrice,
                                'Membership Name',
                            )
                        }
                    />
                </StyledSelection>
            </StyledUserSelection>
            {extras && (
                <StyledUserSelection>
                    <StyledSelectionTitle>Extras:</StyledSelectionTitle>
                    <StyledSelection>
                        <StyledSelectionValue>{extras}</StyledSelectionValue>
                        <StyledChangeButton
                            text="change"
                            buttonStyle="tertiary"
                            href={relativeUrlWithBasePath('/join-now/extras/')}
                        />
                    </StyledSelection>
                </StyledUserSelection>
            )}
            {startDate && membershipType?.includes('DayPass') && (
                <StyledUserSelection>
                    <StyledSelectionTitle>Start Date:</StyledSelectionTitle>
                    <StyledSelection>
                        <StyledSelectionValue>
                            {formatDate(startDate)}
                        </StyledSelectionValue>
                        <StyledChangeButton
                            aria-label="change the start date"
                            text="change"
                            buttonStyle="tertiary"
                            href={relativeUrlWithBasePath(
                                '/join-now/choose-membership/?changeDate=true',
                            )}
                        />
                    </StyledSelection>
                </StyledUserSelection>
            )}
        </StyledSummary>
    );
};

export default MembershipSummary;
