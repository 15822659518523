import { styled } from '@mui/material/styles';

import { palette } from '../../theme';
import { TextInput } from '../Forms/TextInput';
import { Heading } from '../Heading';
import { Tile } from '../Tile';

export const StyledTextInputContainer = styled('div')`
    max-width: 25.5rem;
    margin: auto;
`;

export const StyledHeading = styled(Heading)`
    color: ${palette.common.blue};
`;

export const StyledTextInput = styled(TextInput)<{
    disabled: boolean;
}>`
    ${({ disabled }) =>
        disabled
            ? `
            opacity: 1;
            background-color: ${palette.common.white};

            svg path {
                stroke: ${palette.primary.main};
                fill: ${palette.primary.main};
            }
    `
            : ''};
`;

const StyledPromoCodeTileDefault = styled(Tile)`
    justify-content: center;
    padding: 3rem;
    margin-top: 3rem;
    text-align: center;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-top: 4rem;
    }
`;
StyledPromoCodeTileDefault.defaultProps = {
    variant: 'secondary',
};
export const StyledPromoCodeTile = StyledPromoCodeTileDefault;
