import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState } from 'react';

import { useFormValidation } from '@tgg/form-validation';
import { EventKey, PaymentsType } from '@tgg/services';

import { useSnackBarContext } from '../../contexts';
import { Asterisk } from '../Asterisk';
import {
    ControlledMaskedInput,
    ControlledTextInput,
} from '../ControlledInputs';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';
import { Tickbox } from '../Tickbox';

import { formDefaultValues } from './ChangeDirectDebitForm.constants';
import { directDebitSchema } from './ChangeDirectDebitForm.schemas';
import {
    StyledForm,
    StyledSubmitButton,
    StyledSubmitButtonWrapper,
    StyledHorizontalRule,
    StyledWrapper,
    StyledSmallText,
    StyledAlertMessage,
} from './ChangeDirectDebitForm.styled';
import {
    DirectDebitInputs,
    DirectDebitDetailsProperties,
} from './ChangeDirectDebitForm.types';

export const ChangeDirectDebitForm = ({
    onSubmit,
    onCancel,
    showMemberAgreement = false,
    submitText = 'submit',
    errorMessage,
    ...rest
}: DirectDebitDetailsProperties) => {
    const [, updateSnackBar] = useSnackBarContext();
    const { control, formState, handleSubmit } =
        useFormValidation<DirectDebitInputs>({
            formProps: {
                mode: 'onBlur',
                defaultValues: formDefaultValues,
                resolver: zodResolver(directDebitSchema),
            },
            updateSnackBar,
        });

    const { isValid } = formState;
    const [memberAgreement, setMemberAgreement] = useState<boolean>(false);
    const isMemberAgreementValid = showMemberAgreement ? memberAgreement : true;

    const handleMemberAgreementChange = ({
        target,
    }: React.ChangeEvent<HTMLInputElement>) => {
        setMemberAgreement(target.checked);
    };

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)} {...rest}>
            <StyledWrapper>
                <Paragraph gutterBottom={false}>
                    <Asterisk spaceAfter />
                    Required fields
                </Paragraph>
                <ControlledTextInput
                    name="accountName"
                    label="account holder name"
                    control={control}
                    isRequired
                    id="account-holder-name"
                    placeholder="enter account name"
                />
                <StyledSmallText>
                    Please input the name on your bank account.
                </StyledSmallText>
                <ControlledMaskedInput
                    mask="sortCode"
                    name="sortCode"
                    label="Sort code"
                    isRequired
                    control={control}
                    id="sort-code"
                    placeholder="XX - XX - XX"
                />
                <ControlledTextInput
                    name="accountNumber"
                    label="Account number"
                    isRequired
                    control={control}
                    id="account-number"
                    placeholder="enter account number"
                    inputProps={{
                        maxLength: 8,
                    }}
                />
            </StyledWrapper>
            {showMemberAgreement && (
                <>
                    <StyledHorizontalRule />
                    <Heading variant="h2">Membership agreement</Heading>
                    <Tickbox
                        name="memberAgreement"
                        checked={memberAgreement}
                        onChange={handleMemberAgreementChange}
                    >
                        I confirm that I am the authorised account holder and
                        can authorise debits from this account.
                    </Tickbox>
                </>
            )}

            <StyledWrapper>
                {errorMessage && (
                    <StyledAlertMessage type="alert">
                        {errorMessage}
                    </StyledAlertMessage>
                )}
            </StyledWrapper>

            <StyledSubmitButtonWrapper>
                <StyledSubmitButton
                    type="submit"
                    text={submitText}
                    fullWidth
                    disabled={!isValid || !isMemberAgreementValid}
                    data-testid="change-direct-debit-submit-btn"
                    data-analytics-event={EventKey.CTA_BUTTON_CLICK}
                    data-analytics-payload={JSON.stringify({
                        event: 'monthlyPaymentsType',
                        paymentType: 'directDebit',
                    } as PaymentsType)}
                />
                {onCancel && (
                    <StyledSubmitButton
                        type="button"
                        buttonStyle="secondary"
                        text="cancel"
                        onClick={onCancel}
                        fullWidth
                        data-testid="change-direct-debit-cancel-btn"
                    />
                )}
            </StyledSubmitButtonWrapper>
        </StyledForm>
    );
};

export default ChangeDirectDebitForm;
