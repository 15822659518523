import { palette } from '../../theme';
import { DownloadApp } from '../DownloadApp';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
import { Paragraph } from '../Paragraph';

import {
    DownloadOurAppSection,
    StyledCheckList,
} from './DownloadAppWithBenefitsList.styled';

export interface DownloadAppWithBenefitsListProperTies {
    heading: string;
    subTitle: string;
}

export function DownloadAppWithBenefitsList({
    heading,
    subTitle,
}: DownloadAppWithBenefitsListProperTies) {
    return (
        <DownloadOurAppSection>
            <Heading variant="h3" component="h2" alignOnDesktop="center">
                {heading}
            </Heading>
            <Paragraph>{subTitle}</Paragraph>
            <StyledCheckList>
                <li>
                    <Icon name="tick" color={palette.primary.main} size={16} />
                    Contactless entry
                </li>
                <li>
                    <Icon name="tick" color={palette.primary.main} size={16} />
                    Create or follow a workout
                </li>
                <li>
                    <Icon name="tick" color={palette.primary.main} size={16} />
                    Book classes
                </li>
                <li>
                    <Icon name="tick" color={palette.primary.main} size={16} />
                    Fitness tracking
                </li>
                <li>
                    <Icon name="tick" color={palette.primary.main} size={16} />
                    Live attendance tracker
                </li>
            </StyledCheckList>
            <DownloadApp isShowTextContent={false} />
        </DownloadOurAppSection>
    );
}
