import { useMediaQuery } from '@mui/material';
import { useState, useEffect } from 'react';

import { theme, palette } from '../../theme';
import { getPromoTimes } from '../../utils';
import { SnackBar } from '../SnackBar';

import {
    StyledPromoSnackBar,
    StyledHeadingContent,
    StyledParagraph,
    StyledHeading,
    StyledLink,
} from './PromoSnackBar.styled';
import { PromoSnackBarProperties } from './PromoSnackBar.types';

const blankTimer = {
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
};

// Add leading zero to time
const addZero = (time: number) => `0${time}`.slice(-2);

export const PromoSnackBar = ({
    id,
    message,
    iconName,
    promoStartDate,
    promoEndDate,
    className,
    link,
    isSticky = false,
    color = 'electric green',
}: PromoSnackBarProperties) => {
    const [timeRemaining, setTimeRemaining] = useState(blankTimer);

    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    const { startDate, endDate } = getPromoTimes(promoStartDate, promoEndDate);

    // Show timer if start date and end date are not equal to 0 and start date is not in the future
    const hideTimer =
        startDate === 0 || endDate === 0 || startDate > Date.now();

    useEffect(() => {
        const now = Date.now();
        let timer: null | ReturnType<typeof setTimeout> = null;

        const getTimeRemaining = (date: number) => {
            const remaining = date - now;

            return {
                days: addZero(Math.floor(remaining / (1000 * 60 * 60 * 24))),
                hours: addZero(Math.floor((remaining / (1000 * 60 * 60)) % 24)),
                minutes: addZero(Math.floor((remaining / 1000 / 60) % 60)),
                seconds: addZero(Math.floor((remaining / 1000) % 60)),
            };
        };

        if (endDate && endDate > now) {
            timer = setTimeout(() => {
                setTimeRemaining(getTimeRemaining(endDate));
            }, 1000);
        }

        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [endDate, timeRemaining]);

    const { days, hours, minutes, seconds } = timeRemaining;

    return (
        <StyledPromoSnackBar
            id={id}
            className={className}
            $isSticky={isSticky}
            $paletteColor={color}
        >
            {link ? (
                <StyledLink data-testid="promo link" href={link}>
                    <SnackBar
                        message={message}
                        barType="promo"
                        iconName={iconName}
                        className="promoSnackBar"
                    />
                </StyledLink>
            ) : (
                <SnackBar
                    message={message}
                    barType="promo"
                    iconName={iconName}
                    className="promoSnackBar"
                />
            )}
            {hideTimer ? null : (
                <StyledHeadingContent data-testid="snackbar countdown timer">
                    <StyledHeading
                        variant="h4"
                        component="span"
                        color={palette.common.blue}
                    >
                        {days}
                    </StyledHeading>
                    <StyledParagraph variant="subtitle1">Days</StyledParagraph>
                    <StyledHeading
                        variant="h4"
                        component="span"
                        color={palette.common.blue}
                    >
                        {hours}
                    </StyledHeading>
                    <StyledParagraph variant="subtitle1">
                        {isDesktop ? 'Hours' : 'Hrs'}
                    </StyledParagraph>
                    <StyledHeading
                        variant="h4"
                        component="span"
                        color={palette.common.blue}
                    >
                        {minutes}
                    </StyledHeading>
                    <StyledParagraph variant="subtitle1">
                        {isDesktop ? 'Minutes' : 'Min'}
                    </StyledParagraph>
                    <StyledHeading
                        variant="h4"
                        component="span"
                        color={palette.common.blue}
                    >
                        {seconds}
                    </StyledHeading>
                    <StyledParagraph variant="subtitle1">
                        {isDesktop ? 'Seconds' : 'Sec'}
                    </StyledParagraph>
                </StyledHeadingContent>
            )}
        </StyledPromoSnackBar>
    );
};

export default PromoSnackBar;
